<template>
  <div class="main">
    <PreLoader v-if="isLoading"/>
    <div v-show="!isLoading" class="page-wrapper">
        <div class="topbar-one">
            <div class="container">
                <div class="topbar-one__left">
                    <a href="#">zhyldyzacademy@protonmail.com</a>
                    <a href="#">+996 555 94 95 01</a>
                </div><!-- /.topbar-one__left -->
                <div class="topbar-one__right">
                    <a href="#">Login</a>
                    <a href="#">Register</a>
                </div><!-- /.topbar-one__right -->
            </div><!-- /.container -->
        </div><!-- /.topbar-one -->
        <header class="site-header site-header__header-one ">
            <nav class="navbar navbar-expand-lg navbar-light header-navigation stricky">
                <div class="container clearfix">
                    <!-- Brand and toggle get grouped for better mobile display -->
                    <div class="logo-box clearfix">
                        <a class="navbar-brand" href="index.html">
                            <img :src="require('../../assets/logo_1.png')" class="main-logo" width="128" alt="Awesome Image" />
                        </a>
                        <div class="header__social">
                            <a href="#" target="_blank"><i class="fab fa-twitter"></i></a>
                            <a href="https://www.facebook.com/zhyldyz.academy" target="_blank"><i class="fab fa-facebook-square"></i></a>
                            <a href="https://api.whatsapp.com/send/?phone=%2B996704949501&text&app_absent=0" target="_blank"><i class="fab fa-whatsapp"></i></a>
                            <a href="https://www.instagram.com/zhyldyz_academy/" target="_blank"><i class="fab fa-instagram"></i></a>
                        </div><!-- /.header__social -->
                        <button class="menu-toggler" data-target=".main-navigation">
                            <span class="kipso-icon-menu"></span>
                        </button>
                    </div><!-- /.logo-box -->
                    <!-- Collect the nav links, forms, and other content for toggling -->
                    <div class="main-navigation">
                        <ul class=" navigation-box">
                            <li class="current">
                                <a @click="goTo('Home')" style="font-weight: 700;">Home</a>
                               <!--<ul class="sub-menu">
                                    <li><a href="index.html">Home 01</a></li>
                                    <li><a href="index-2.html">Home 02</a></li>
                                    <li><a href="index-3.html">Home 03</a></li>
                                    <li><a href="#">Header Versions</a>
                                        <ul class="sub-menu">
                                            <li><a href="index.html">Header 01</a></li>
                                            <li><a href="index-2.html">Header 02</a></li>
                                            <li><a href="index-3.html">Header 03</a></li>
                                        </ul>
                                    </li>
                                </ul> /.sub-menu -->
                            </li>
                            <!--<li>
                                <a href="#">Pages</a>
                                <ul class="sub-menu">
                                    <li><a href="about.html">About Page</a></li>
                                    <li><a href="gallery.html">Gallery</a></li>
                                    <li><a href="pricing.html">Pricing Plans</a></li>
                                    <li><a href="faq.html">FAQ'S</a></li>
                                </ul> 
                            </li>/.sub-menu -->
                            <li >
                                <a @click="goTo('Courses')" style="font-weight: 700;">Courses</a>
                                <!--<ul class="sub-menu">
                                    <li><a href="courses.html">Courses</a></li>
                                    <li><a href="course-details.html">Course Details</a></li>
                                </ul> /.sub-menu -->
                            </li>
                            <li >
                                <a @click="goTo('Teachers')" style="font-weight: 700;">Teachers</a>
                                <!--<ul class="sub-menu">
                                    <li><a href="teachers.html">Teachers</a></li>
                                    <li><a href="team-details.html">Teachers Details</a></li>
                                    <li><a href="become-teacher.html">Become Teacher</a></li>
                                </ul> /.sub-menu -->
                            </li>
                            <li >
                                <a @click="goTo('News')" style="font-weight: 700;">News</a>
                                <!-- <ul class="sub-menu">
                                    <li><a href="news.html">News Page</a></li>
                                    <li><a href="news-details.html">News Details</a></li>
                                </ul>/.sub-menu -->
                            </li>
                            <li >
                                <a @click="goTo('About')" style="font-weight: 700;">About</a>
                            </li>
                        </ul>
                    </div><!-- /.navbar-collapse -->
                    <div class="right-side-box">
                        <a class="header__search-btn search-popup__toggler" href="#"><i class="kipso-icon-magnifying-glass"></i>
                            <!-- /.kipso-icon-magnifying-glass --></a>
                    </div><!-- /.right-side-box -->
                </div>
                <!-- /.container -->
            </nav>
            <nav class="navbar navbar-expand-lg navbar-light header-navigation stricky stricked-menu stricky-fixed">
                <div class="container clearfix">
                    <!-- Brand and toggle get grouped for better mobile display -->
                    <div class="logo-box clearfix">
                        <a class="navbar-brand" href="index.html">
                            <img :src="require('../../assets/logo_1.png')" class="main-logo" width="128" alt="Awesome Image" />
                        </a>
                        <div class="header__social">
                            <a href="#" target="_blank"><i class="fab fa-twitter"></i></a>
                            <a href="https://www.facebook.com/zhyldyz.academy" target="_blank"><i class="fab fa-facebook-square"></i></a>
                            <a href="https://api.whatsapp.com/send/?phone=%2B996704949501&text&app_absent=0" target="_blank"><i class="fab fa-whatsapp"></i></a>
                            <a href="https://www.instagram.com/zhyldyz_academy/" target="_blank"><i class="fab fa-instagram"></i></a>
                        </div><!-- /.header__social -->
                        <button class="menu-toggler" data-target=".main-navigation">
                            <span class="kipso-icon-menu"></span>
                        </button>
                    </div><!-- /.logo-box -->
                    <!-- Collect the nav links, forms, and other content for toggling -->
                    <div class="main-navigation" style="display: none;">
                        <ul class=" navigation-box">
                            <li class="current">
                                <a @click="goTo('Home')">Home<button class="sub-nav-toggler"> <span class="sr-only">Toggle navigation</span> <span class="icon-bar"></span> <span class="icon-bar"></span> <span class="icon-bar"></span> </button></a>
                                
                            </li>
                            <li>
                                <a @click="goTo('Courses')">Courses<button class="sub-nav-toggler"> <span class="sr-only">Toggle navigation</span> <span class="icon-bar"></span> <span class="icon-bar"></span> <span class="icon-bar"></span> </button></a>
                                
                            </li>
                            <li>
                                <a @click="goTo('Teachers')">Teachers<button class="sub-nav-toggler"> <span class="sr-only">Toggle navigation</span> <span class="icon-bar"></span> <span class="icon-bar"></span> <span class="icon-bar"></span> </button></a>
                                <!-- <ul class="sub-menu">
                                    <li><a href="teachers.html">Teachers</a></li>
                                    <li><a href="team-details.html">Teachers Details</a></li>
                                    <li><a href="become-teacher.html">Become Teacher</a></li>
                                </ul>/.sub-menu -->
                            </li>
                            <li>
                                <a @click="goTo('News')">News<button class="sub-nav-toggler"> <span class="sr-only">Toggle navigation</span> <span class="icon-bar"></span> <span class="icon-bar"></span> <span class="icon-bar"></span> </button></a>
                                
                            </li>
                            <li>
                                <a @click="goTo('About')">About</a>
                            </li>
                        </ul>
                    </div><!-- /.navbar-collapse -->
                    <div class="right-side-box">
                        <a class="header__search-btn search-popup__toggler" href="#"><i class="kipso-icon-magnifying-glass"></i>
                            <!-- /.kipso-icon-magnifying-glass --></a>
                    </div><!-- /.right-side-box -->
                </div>
                <!-- /.container -->
            </nav>
            <div class="site-header__decor">
                <div class="site-header__decor-row">
                    <div class="site-header__decor-single">
                        <div class="site-header__decor-inner-1"></div><!-- /.site-header__decor-inner -->
                    </div><!-- /.site-header__decor-single -->
                    <div class="site-header__decor-single">
                        <div class="site-header__decor-inner-2"></div><!-- /.site-header__decor-inner -->
                    </div><!-- /.site-header__decor-single -->
                    <div class="site-header__decor-single">
                        <div class="site-header__decor-inner-3"></div><!-- /.site-header__decor-inner -->
                    </div><!-- /.site-header__decor-single -->
                </div><!-- /.site-header__decor-row -->
            </div><!-- /.site-header__decor -->
        </header><!-- /.site-header -->
        <div class="banner-wrapper">
            <section class="banner-one banner-carousel__one no-dots owl-theme owl-carousel">
                <div class="banner-one__slide banner-one__slide-one">
                    <div class="container">
                        <div class="banner-one__bubble-1"></div><!-- /.banner-one__bubble- -->
                        <div class="banner-one__bubble-2"></div><!-- /.banner-one__bubble- -->
                        <div class="banner-one__bubble-3"></div><!-- /.banner-one__bubble- -->
                        <img :src="require('../../assets/images/slider-1-scratch.png')" alt="" class="banner-one__scratch">
                        <img :src="require('../../assets/images/slider-1-person-1.png')" class="banner-one__person" alt="">
                        <div class="row no-gutters">
                            <div class="col-xl-12">
                                <h3 class="banner-one__title banner-one__light-color">We Can <br>
                                    Teach You</h3><!-- /.banner-one__title -->
                                <p class="banner-one__tag-line">are you ready to learn?</p><!-- /.banner-one__tag-line -->
                                <a href="#" class="thm-btn banner-one__btn">Learn More</a>
                            </div><!-- /.col-xl-12 -->
                        </div><!-- /.row -->
                    </div><!-- /.container -->
                </div><!-- /.banner-one__slide -->
                <div class="banner-one__slide banner-one__slide-two">
                    <div class="container">
                        <div class="banner-one__bubble-1"></div><!-- /.banner-one__bubble- -->
                        <div class="banner-one__bubble-2"></div><!-- /.banner-one__bubble- -->
                        <div class="banner-one__bubble-3"></div><!-- /.banner-one__bubble- -->
                        <img :src="require('../../assets/images/slider-1-scratch.png')" alt="" class="banner-one__scratch">
                        <img :src="require('../../assets/images/slider-1-person-2.png')" class="banner-one__person" alt="">
                        <div class="row no-gutters">
                            <div class="col-xl-12">
                                <h3 class="banner-one__title banner-one__light-color">We Can <br>
                                    Teach You</h3><!-- /.banner-one__title -->
                                <p class="banner-one__tag-line">are you ready to learn?</p><!-- /.banner-one__tag-line -->
                                <a href="#" class="thm-btn banner-one__btn">Learn More</a>
                            </div><!-- /.col-xl-12 -->
                        </div><!-- /.row -->
                    </div><!-- /.container -->
                </div><!-- /.banner-one__slide -->
            </section><!-- /.banner-one -->
            <div class="banner-carousel-btn">
                <a href="#" class="banner-carousel-btn__left-btn"><i class="kipso-icon-left-arrow"></i></a>
                <a href="#" class="banner-carousel-btn__right-btn"><i class="kipso-icon-right-arrow"></i></a>
            </div><!-- /.banner-carousel-btn -->
            <div class="banner-one__cta">
                <div class="banner-one__cta-icon">
                    <i class="kipso-icon-black-graduation-cap-tool-of-university-student-for-head"></i>
                    <!-- /.kipso-icon-knowledge -->
                </div><!-- /.banner-one__cta-icon -->
                <div class="banner-one__cta-title">
                    <h3 class="banner-one__cta-text"><a href="#">Read how we encourage our
                            students to learn</a></h3><!-- /.banner-one__cta-text -->
                </div><!-- /.banner-one__cta-title -->
                <div class="banner-one__cta-link">
                    <a href="#"><i class="kipso-icon-right-arrow"></i><!-- /.kipso-icon-right-arrow --></a>
                </div><!-- /.banner-one__cta-link -->
            </div><!-- /.banner-one__cta -->
        </div><!-- /.banner-wrapper -->
        <section class="about-two">
            <div class="container">
                <div class="row">
                    <div class="col-xl-6">
                        <div class="about-two__content">
                            <div class="block-title text-left">
                                <h2 class="block-title__title">Welcome to online <br>
                                    learning center</h2><!-- /.block-title__title -->
                            </div><!-- /.block-title -->
                            <p class="about-two__text">There are many variations of passages of lorem ipsum available but the
                                majority have suffered alteration in some form by injected humour or randomised words which
                                don't look.</p><!-- /.about-two__text -->
                            <div class="about-two__single-wrap">
                                <div class="about-two__single">
                                    <div class="about-two__single-icon">
                                        <i class="kipso-icon-professor"></i><!-- /.kipso-icon-professor -->
                                    </div><!-- /.about-two__single-icon -->
                                    <div class="about-two__single-content">
                                        <p class="about-two__single-text">Start learning from
                                            our experts</p><!-- /.about-two__single-text -->
                                    </div><!-- /.about-two__single-content -->
                                </div><!-- /.about-two__single -->
                                <div class="about-two__single">
                                    <div class="about-two__single-icon">
                                        <i class="kipso-icon-knowledge"></i><!-- /.kipso-icon-professor -->
                                    </div><!-- /.about-two__single-icon -->
                                    <div class="about-two__single-content">
                                        <p class="about-two__single-text">Enhance your skills
                                            with us now</p><!-- /.about-two__single-text -->
                                    </div><!-- /.about-two__single-content -->
                                </div><!-- /.about-two__single -->
                            </div><!-- /.about-two__single-wrap -->
                            <a href="#" class="thm-btn" >Learn More</a><!-- /.thm-btn -->
                        </div><!-- /.about-two__content -->
                    </div><!-- /.col-lg-6 -->
                    <div class="col-xl-6 d-flex justify-content-xl-end justify-content-sm-center">
                        <div class="about-two__image">
                            <span class="about-two__image-dots"></span><!-- /.about-two__image-dots -->
                            <img :src="require('../../assets/images/about-1-1.jpeg')" alt="">
                            <div class="about-two__count">
                                <div class="about-two__count-text">Trusted by
                                    <span class="counter">4890</span></div><!-- /.about-two__count-text -->
                            </div><!-- /.about-two__count -->
                        </div><!-- /.about-two__image -->
                    </div><!-- /.col-lg-6 -->
                </div><!-- /.row -->
            </div><!-- /.container -->
        </section><!-- /.about-two -->
        <section class="course-one__top-title home-one">
            <div class="container">
                <div class="block-title mb-0">
                    <h2 class="block-title__title">Explore our <br>
                        popular courses</h2><!-- /.block-title__title -->
                </div><!-- /.block-title -->
            </div><!-- /.container -->
            <div class="course-one__top-title__curve"></div><!-- /.course-one__top-title__curve -->
        </section><!-- /.course-one__top-title -->

        <section class="course-one course-one__teacher-details home-one">
            <div class="container">
                <div class="course-one__carousel owl-carousel owl-theme">
                    <div class="item">
                        <div class="course-one__single color-1">
                            <div class="course-one__image">
                                <img :src="require('../../assets/images/course-1-1.jpeg')" alt="">
                                <i class="far fa-heart"></i><!-- /.far fa-heart -->
                            </div><!-- /.course-one__image -->
                            <div class="course-one__content">
                                <a href="#" class="course-one__category" style="color: #fff;">Beginner</a><!-- /.course-one__category -->
                                <div class="course-one__admin">
                                    <img :src="require('../../assets/images/team-1-1.jpeg')" alt="">
                                    by <a href="teacher-details.html">Lou Guerrero</a>
                                </div><!-- /.course-one__admin -->
                                <h2 class="course-one__title"><a href="course-details.html">New react bootcamp</a></h2>
                                <!-- /.course-one__title -->
                                <div class="course-one__stars">
                                    <span class="course-one__stars-wrap">
                                        <i class="fa fa-star"></i>
                                        <i class="fa fa-star"></i>
                                        <i class="fa fa-star"></i>
                                        <i class="fa fa-star"></i>
                                        <i class="fa fa-star"></i>
                                    </span><!-- /.course-one__stars-wrap -->
                                    <span class="course-one__count">4.8</span><!-- /.course-one__count -->
                                    <span class="course-one__stars-count">250</span><!-- /.course-one__stars-count -->
                                </div><!-- /.course-one__stars -->
                                <div class="course-one__meta">
                                    <a href="course-details.html"><i class="far fa-clock"></i> 10 Hours</a>
                                    <a href="course-details.html"><i class="far fa-folder-open"></i> 6 Lectures</a>
                                    <a href="course-details.html">$18</a>
                                </div><!-- /.course-one__meta -->
                                <a href="#" class="course-one__link">See Preview</a><!-- /.course-one__link -->
                            </div><!-- /.course-one__content -->
                        </div><!-- /.course-one__single -->
                    </div><!-- /.item -->
                    <div class="item">
                        <div class="course-one__single color-2">
                            <div class="course-one__image">
                                <img :src="require('../../assets/images/course-1-2.jpeg')" alt="">
                                <i class="far fa-heart"></i><!-- /.far fa-heart -->
                            </div><!-- /.course-one__image -->
                            <div class="course-one__content">
                                <a href="#" class="course-one__category" style="color: #fff;">Elementary</a><!-- /.course-one__category -->
                                <div class="course-one__admin">
                                    <img :src="require('../../assets/images/team-1-2.jpeg')" alt="">
                                    by <a href="teacher-details.html">Cora Diaz</a>
                                </div><!-- /.course-one__admin -->
                                <h2 class="course-one__title"><a href="course-details.html">Improve editing skills</a></h2>
                                <!-- /.course-one__title -->
                                <div class="course-one__stars">
                                    <span class="course-one__stars-wrap">
                                        <i class="fa fa-star"></i>
                                        <i class="fa fa-star"></i>
                                        <i class="fa fa-star"></i>
                                        <i class="fa fa-star"></i>
                                        <i class="fa fa-star"></i>
                                    </span><!-- /.course-one__stars-wrap -->
                                    <span class="course-one__count">4.8</span><!-- /.course-one__count -->
                                    <span class="course-one__stars-count">250</span><!-- /.course-one__stars-count -->
                                </div><!-- /.course-one__stars -->
                                <div class="course-one__meta">
                                    <a href="course-details.html"><i class="far fa-clock"></i> 10 Hours</a>
                                    <a href="course-details.html"><i class="far fa-folder-open"></i> 6 Lectures</a>
                                    <a href="course-details.html">$18</a>
                                </div><!-- /.course-one__meta -->
                                <a href="#" class="course-one__link">See Preview</a><!-- /.course-one__link -->
                            </div><!-- /.course-one__content -->
                        </div><!-- /.course-one__single -->
                    </div><!-- /.item -->
                    <div class="item">
                        <div class="course-one__single color-3">
                            <div class="course-one__image">
                                <img :src="require('../../assets/images/course-1-3.jpeg')" alt="">
                                <i class="far fa-heart"></i><!-- /.far fa-heart -->
                            </div><!-- /.course-one__image -->
                            <div class="course-one__content">
                                <a href="#" class="course-one__category" style="color: #fff;">Pre-Intermediate</a><!-- /.course-one__category -->
                                <div class="course-one__admin">
                                    <img :src="require('../../assets/images/team-1-3.jpeg')" alt="">
                                    by <a href="teacher-details.html">Ruth Becker</a>
                                </div><!-- /.course-one__admin -->
                                <h2 class="course-one__title"><a href="course-details.html">Marketing strategies</a></h2>
                                <!-- /.course-one__title -->
                                <div class="course-one__stars">
                                    <span class="course-one__stars-wrap">
                                        <i class="fa fa-star"></i>
                                        <i class="fa fa-star"></i>
                                        <i class="fa fa-star"></i>
                                        <i class="fa fa-star"></i>
                                        <i class="fa fa-star"></i>
                                    </span><!-- /.course-one__stars-wrap -->
                                    <span class="course-one__count">4.8</span><!-- /.course-one__count -->
                                    <span class="course-one__stars-count">250</span><!-- /.course-one__stars-count -->
                                </div><!-- /.course-one__stars -->
                                <div class="course-one__meta">
                                    <a href="course-details.html"><i class="far fa-clock"></i> 10 Hours</a>
                                    <a href="course-details.html"><i class="far fa-folder-open"></i> 6 Lectures</a>
                                    <a href="course-details.html">$18</a>
                                </div><!-- /.course-one__meta -->
                                <a href="#" class="course-one__link">See Preview</a><!-- /.course-one__link -->
                            </div><!-- /.course-one__content -->
                        </div><!-- /.course-one__single -->
                    </div><!-- /.item -->
                    <div class="item">
                        <div class="course-one__single color-4">
                            <div class="course-one__image">
                                <img :src="require('../../assets/images/course-1-4.jpeg')" alt="">
                                <i class="far fa-heart"></i><!-- /.far fa-heart -->
                            </div><!-- /.course-one__image -->
                            <div class="course-one__content">
                                <a href="#" class="course-one__category" style="color: #fff;">ОРТ Математика</a><!-- /.course-one__category -->
                                <div class="course-one__admin">
                                    <img :src="require('../../assets/images/team-1-4.jpeg')" alt="">
                                    by <a href="teacher-details.html">Ernest Rodriquez</a>
                                </div><!-- /.course-one__admin -->
                                <h2 class="course-one__title"><a href="course-details.html">Basics of photography</a></h2>
                                <!-- /.course-one__title -->
                                <div class="course-one__stars">
                                    <span class="course-one__stars-wrap">
                                        <i class="fa fa-star"></i>
                                        <i class="fa fa-star"></i>
                                        <i class="fa fa-star"></i>
                                        <i class="fa fa-star"></i>
                                        <i class="fa fa-star"></i>
                                    </span><!-- /.course-one__stars-wrap -->
                                    <span class="course-one__count">4.8</span><!-- /.course-one__count -->
                                    <span class="course-one__stars-count">250</span><!-- /.course-one__stars-count -->
                                </div><!-- /.course-one__stars -->
                                <div class="course-one__meta">
                                    <a href="course-details.html"><i class="far fa-clock"></i> 10 Hours</a>
                                    <a href="course-details.html"><i class="far fa-folder-open"></i> 6 Lectures</a>
                                    <a href="course-details.html">$18</a>
                                </div><!-- /.course-one__meta -->
                                <a href="#" class="course-one__link">See Preview</a><!-- /.course-one__link -->
                            </div><!-- /.course-one__content -->
                        </div><!-- /.course-one__single -->
                    </div><!-- /.item -->
                    <div class="item">
                        <div class="course-one__single color-5">
                            <div class="course-one__image">
                                <img :src="require('../../assets/images/course-1-5.jpeg')" alt="">
                                <i class="far fa-heart"></i><!-- /.far fa-heart -->
                            </div><!-- /.course-one__image -->
                            <div class="course-one__content">
                                <a href="#" class="course-one__category" style="color: #fff;">ОРТ Русский язык</a><!-- /.course-one__category -->
                                <div class="course-one__admin">
                                    <img :src="require('../../assets/images/team-1-5.jpeg')" alt="">
                                    by <a href="teacher-details.html">Isabella Stanley</a>
                                </div><!-- /.course-one__admin -->
                                <h2 class="course-one__title"><a href="course-details.html">Affiliate bootcamp</a>
                                </h2>
                                <!-- /.course-one__title -->
                                <div class="course-one__stars">
                                    <span class="course-one__stars-wrap">
                                        <i class="fa fa-star"></i>
                                        <i class="fa fa-star"></i>
                                        <i class="fa fa-star"></i>
                                        <i class="fa fa-star"></i>
                                        <i class="fa fa-star"></i>
                                    </span><!-- /.course-one__stars-wrap -->
                                    <span class="course-one__count">4.8</span><!-- /.course-one__count -->
                                    <span class="course-one__stars-count">250</span><!-- /.course-one__stars-count -->
                                </div><!-- /.course-one__stars -->
                                <div class="course-one__meta">
                                    <a href="course-details.html"><i class="far fa-clock"></i> 10 Hours</a>
                                    <a href="course-details.html"><i class="far fa-folder-open"></i> 6 Lectures</a>
                                    <a href="course-details.html">$18</a>
                                </div><!-- /.course-one__meta -->
                                <a href="#" class="course-one__link">See Preview</a><!-- /.course-one__link -->
                            </div><!-- /.course-one__content -->
                        </div><!-- /.course-one__single -->
                    </div><!-- /.item -->
                    <div class="item">
                        <div class="course-one__single color-6">
                            <div class="course-one__image">
                                <img :src="require('../../assets/images/course-1-6.jpeg')" alt="">
                                <i class="far fa-heart"></i><!-- /.far fa-heart -->
                            </div><!-- /.course-one__image -->
                            <div class="course-one__content">
                                <a href="#" class="course-one__category" style="color: #fff;">ОРТ Кыргызский язык</a><!-- /.course-one__category -->
                                <div class="course-one__admin">
                                    <img :src="require('../../assets/images/team-1-6.jpeg')" alt="">
                                    by <a href="teacher-details.html">Katherine Collins</a>
                                </div><!-- /.course-one__admin -->
                                <h2 class="course-one__title"><a href="course-details.html">Healthy workout tips </a></h2>
                                <!-- /.course-one__title -->
                                <div class="course-one__stars">
                                    <span class="course-one__stars-wrap">
                                        <i class="fa fa-star"></i>
                                        <i class="fa fa-star"></i>
                                        <i class="fa fa-star"></i>
                                        <i class="fa fa-star"></i>
                                        <i class="fa fa-star"></i>
                                    </span><!-- /.course-one__stars-wrap -->
                                    <span class="course-one__count">4.8</span><!-- /.course-one__count -->
                                    <span class="course-one__stars-count">250</span><!-- /.course-one__stars-count -->
                                </div><!-- /.course-one__stars -->
                                <div class="course-one__meta">
                                    <a href="course-details.html"><i class="far fa-clock"></i> 10 Hours</a>
                                    <a href="course-details.html"><i class="far fa-folder-open"></i> 6 Lectures</a>
                                    <a href="course-details.html">$18</a>
                                </div><!-- /.course-one__meta -->
                                <a href="#" class="course-one__link">See Preview</a><!-- /.course-one__link -->
                            </div><!-- /.course-one__content -->
                        </div><!-- /.course-one__single -->
                    </div><!-- /.item -->
                    <div class="item">
                        <div class="course-one__single color-1">
                            <div class="course-one__image">
                                <img :src="require('../../assets/images/course-1-1.jpeg')" alt="">
                                <i class="far fa-heart"></i><!-- /.far fa-heart -->
                            </div><!-- /.course-one__image -->
                            <div class="course-one__content">
                                <a href="#" class="course-one__category" style="color: #fff;">ОРТ Английский язык</a><!-- /.course-one__category -->
                                <div class="course-one__admin">
                                    <img :src="require('../../assets/images/team-1-1.jpeg')" alt="">
                                    by <a href="teacher-details.html">Lou Guerrero</a>
                                </div><!-- /.course-one__admin -->
                                <h2 class="course-one__title"><a href="course-details.html">New react bootcamp</a></h2>
                                <!-- /.course-one__title -->
                                <div class="course-one__stars">
                                    <span class="course-one__stars-wrap">
                                        <i class="fa fa-star"></i>
                                        <i class="fa fa-star"></i>
                                        <i class="fa fa-star"></i>
                                        <i class="fa fa-star"></i>
                                        <i class="fa fa-star"></i>
                                    </span><!-- /.course-one__stars-wrap -->
                                    <span class="course-one__count">4.8</span><!-- /.course-one__count -->
                                    <span class="course-one__stars-count">250</span><!-- /.course-one__stars-count -->
                                </div><!-- /.course-one__stars -->
                                <div class="course-one__meta">
                                    <a href="course-details.html"><i class="far fa-clock"></i> 10 Hours</a>
                                    <a href="course-details.html"><i class="far fa-folder-open"></i> 6 Lectures</a>
                                    <a href="course-details.html">$18</a>
                                </div><!-- /.course-one__meta -->
                                <a href="#" class="course-one__link">See Preview</a><!-- /.course-one__link -->
                            </div><!-- /.course-one__content -->
                        </div><!-- /.course-one__single -->
                    </div><!-- /.item -->
                    <div class="item">
                        <div class="course-one__single color-2">
                            <div class="course-one__image">
                                <img :src="require('../../assets/images/course-1-2.jpeg')" alt="">
                                <i class="far fa-heart"></i><!-- /.far fa-heart -->
                            </div><!-- /.course-one__image -->
                            <div class="course-one__content">
                                <a href="#" class="course-one__category" style="color: #fff;">ОРТ Основной тест</a><!-- /.course-one__category -->
                                <div class="course-one__admin">
                                    <img :src="require('../../assets/images/team-1-2.jpeg')" alt="">
                                    by <a href="teacher-details.html">Cora Diaz</a>
                                </div><!-- /.course-one__admin -->
                                <h2 class="course-one__title"><a href="course-details.html">Improve editing skills</a></h2>
                                <!-- /.course-one__title -->
                                <div class="course-one__stars">
                                    <span class="course-one__stars-wrap">
                                        <i class="fa fa-star"></i>
                                        <i class="fa fa-star"></i>
                                        <i class="fa fa-star"></i>
                                        <i class="fa fa-star"></i>
                                        <i class="fa fa-star"></i>
                                    </span><!-- /.course-one__stars-wrap -->
                                    <span class="course-one__count">4.8</span><!-- /.course-one__count -->
                                    <span class="course-one__stars-count">250</span><!-- /.course-one__stars-count -->
                                </div><!-- /.course-one__stars -->
                                <div class="course-one__meta">
                                    <a href="course-details.html"><i class="far fa-clock"></i> 10 Hours</a>
                                    <a href="course-details.html"><i class="far fa-folder-open"></i> 6 Lectures</a>
                                    <a href="course-details.html">$18</a>
                                </div><!-- /.course-one__meta -->
                                <a href="#" class="course-one__link">See Preview</a><!-- /.course-one__link -->
                            </div><!-- /.course-one__content -->
                        </div><!-- /.course-one__single -->
                    </div><!-- /.item -->
                    <div class="item">
                        <div class="course-one__single color-3">
                            <div class="course-one__image">
                                <img :src="require('../../assets/images/course-1-3.jpeg')" alt="">
                                <i class="far fa-heart"></i><!-- /.far fa-heart -->
                            </div><!-- /.course-one__image -->
                            <div class="course-one__content">
                                <a href="#" class="course-one__category" style="color: #fff;">ОРТ Предметы</a><!-- /.course-one__category -->
                                <div class="course-one__admin">
                                    <img :src="require('../../assets/images/team-1-3.jpeg')" alt="">
                                    by <a href="teacher-details.html">Ruth Becker</a>
                                </div><!-- /.course-one__admin -->
                                <h2 class="course-one__title"><a href="course-details.html">Marketing strategies</a></h2>
                                <!-- /.course-one__title -->
                                <div class="course-one__stars">
                                    <span class="course-one__stars-wrap">
                                        <i class="fa fa-star"></i>
                                        <i class="fa fa-star"></i>
                                        <i class="fa fa-star"></i>
                                        <i class="fa fa-star"></i>
                                        <i class="fa fa-star"></i>
                                    </span><!-- /.course-one__stars-wrap -->
                                    <span class="course-one__count">4.8</span><!-- /.course-one__count -->
                                    <span class="course-one__stars-count">250</span><!-- /.course-one__stars-count -->
                                </div><!-- /.course-one__stars -->
                                <div class="course-one__meta">
                                    <a href="course-details.html"><i class="far fa-clock"></i> 10 Hours</a>
                                    <a href="course-details.html"><i class="far fa-folder-open"></i> 6 Lectures</a>
                                    <a href="course-details.html">$18</a>
                                </div><!-- /.course-one__meta -->
                                <a href="#" class="course-one__link">See Preview</a><!-- /.course-one__link -->
                            </div><!-- /.course-one__content -->
                        </div><!-- /.course-one__single -->
                    </div><!-- /.item -->
                </div><!-- /.course-one__carousel -->
            </div><!-- /.container -->
        </section><!-- /.course-one course-page -->
        <section class="video-two">
            <div class="container">
                <img :src="require('../../assets/images/scratch-1-1.png')" class="video-two__scratch" alt="">
                <div class="row">
                    <div class="col-lg-7">
                        <div class="video-two__content">
                            <h2 class="video-two__title">Zhyldyz academy one &  <br>
                                only mission is to extend <br>
                                your knowledge base</h2><!-- /.video-two__title -->
                            <a href="#" class="thm-btn">Learn More</a><!-- /.thm-btn -->
                        </div><!-- /.video-two__content -->
                    </div><!-- /.col-lg-7 -->
                    <div class="col-lg-5 d-flex justify-content-lg-end justify-content-sm-start">
                        <div class="my-auto">
                            <a href="#" class="video-two__popup"><i class="fa fa-play"></i><!-- /.fa fa-play --></a>
                            <!-- /.video-two__popup -->
                        </div><!-- /.my-auto -->
                    </div><!-- /.col-lg-5 d-flex justify-content-end -->
                </div><!-- /.row -->
            </div><!-- /.container -->
        </section><!-- /.video-two -->
        <!--<section class="countdown-one">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="countdown-one__content">
                            <h2 class="countdown-one__title">Register now </h2>
                            <p class="countdown-one__tag-line">get premium online courses for free!</p>
                            <p class="countdown-one__text">Lorem ipsum gravida nibh vel velit auctor aliquetnean sollicitudin,
                                lorem
                                quis bibendum auci elit consequat is simply free text available in the psutis sem nibh id eis
                                sed
                                odio sit amet.</p>
                            <ul class="countdown-one__list list-unstyled">
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="become-teacher__form">
                            <div class="become-teacher__form-top">
                                <h2 class="become-teacher__form-title">
                                    Get free courses
                                </h2>
                            </div>
                            <form action="assets/inc/sendemail.php" class="become-teacher__form-content contact-form-validated">
                                <input type="text" placeholder="Your Name" name="name">
                                <input type="text" placeholder="Email Address" name="email">
                                <input type="text" placeholder="Phone Number" name="phone">
                                <input type="text" placeholder="Comment" name="message">
                                <button type="submit" class="thm-btn become-teacher__form-btn">Apply For It</button>
                            </form>
                            <div class="result text-center"></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>-->
        <section class="thm-gray-bg course-category-one">
            <div class="container-fluid text-center">
                <div class="block-title text-center">
                    <h2 class="block-title__title">Browse online <br>
                        course categories</h2><!-- /.block-title__title -->
                </div><!-- /.block-title -->
                <div class="course-category-one__carousel owl-carousel owl-theme">
                    <div class="item">
                        <div class="course-category-one__single color-1">
                            <div class="course-category-one__icon">
                                <i class="kipso-icon-desktop"></i><!-- /.kipso-icon-camera -->
                            </div><!-- /.course-category-one__icon -->
                            <h3 class="course-category-one__title"><a href="#">Beginner</a></h3>
                            <!-- /.course-category-one__title -->
                        </div><!-- /.course-category-one__single -->
                    </div><!-- /.item -->
                    <div class="item">
                        <div class="course-category-one__single color-2">
                            <div class="course-category-one__icon">
                                <i class="kipso-icon-web-programming"></i><!-- /.kipso-icon-camera -->
                            </div><!-- /.course-category-one__icon -->
                            <h3 class="course-category-one__title"><a href="#">Elementary</a></h3>
                            <!-- /.course-category-one__title -->
                        </div><!-- /.course-category-one__single -->
                    </div><!-- /.item -->
                    <div class="item">
                        <div class="course-category-one__single color-3">
                            <div class="course-category-one__icon">
                                <i class="kipso-icon-music-player"></i><!-- /.kipso-icon-camera -->
                            </div><!-- /.course-category-one__icon -->
                            <h3 class="course-category-one__title"><a href="#">Pre-Intermediate</a></h3>
                            <!-- /.course-category-one__title -->
                        </div><!-- /.course-category-one__single -->
                    </div><!-- /.item -->
                    <div class="item">
                        <div class="course-category-one__single color-4">
                            <div class="course-category-one__icon">
                                <i class="kipso-icon-camera"></i><!-- /.kipso-icon-camera -->
                            </div><!-- /.course-category-one__icon -->
                            <h3 class="course-category-one__title"><a href="#">ОРТ Основной Тест</a></h3>
                            <!-- /.course-category-one__title -->
                        </div><!-- /.course-category-one__single -->
                    </div><!-- /.item -->
                    <div class="item">
                        <div class="course-category-one__single color-5">
                            <div class="course-category-one__icon">
                                <i class="kipso-icon-targeting"></i><!-- /.kipso-icon-camera -->
                            </div><!-- /.course-category-one__icon -->
                            <h3 class="course-category-one__title"><a href="#">ОРТ Математика</a></h3>
                            <!-- /.course-category-one__title -->
                        </div><!-- /.course-category-one__single -->
                    </div><!-- /.item -->
                    <div class="item">
                        <div class="course-category-one__single color-6">
                            <div class="course-category-one__icon">
                                <i class="kipso-icon-health"></i><!-- /.kipso-icon-camera -->
                            </div><!-- /.course-category-one__icon -->
                            <h3 class="course-category-one__title"><a href="#">ОРТ Русский язык</a></h3>
                            <!-- /.course-category-one__title -->
                        </div><!-- /.course-category-one__single -->
                    </div><!-- /.item -->
                    <div class="item">
                        <div class="course-category-one__single color-1">
                            <div class="course-category-one__icon">
                                <i class="kipso-icon-desktop"></i><!-- /.kipso-icon-camera -->
                            </div><!-- /.course-category-one__icon -->
                            <h3 class="course-category-one__title"><a href="#">ОРТ Кыгызский язык</a></h3>
                            <!-- /.course-category-one__title -->
                        </div><!-- /.course-category-one__single -->
                    </div><!-- /.item -->
                    <div class="item">
                        <div class="course-category-one__single color-2">
                            <div class="course-category-one__icon">
                                <i class="kipso-icon-web-programming"></i><!-- /.kipso-icon-camera -->
                            </div><!-- /.course-category-one__icon -->
                            <h3 class="course-category-one__title"><a href="#">ОРТ Английский язык</a></h3>
                            <!-- /.course-category-one__title -->
                        </div><!-- /.course-category-one__single -->
                    </div><!-- /.item -->
                    <div class="item">
                        <div class="course-category-one__single color-3">
                            <div class="course-category-one__icon">
                                <i class="kipso-icon-music-player"></i><!-- /.kipso-icon-camera -->
                            </div><!-- /.course-category-one__icon -->
                            <h3 class="course-category-one__title"><a href="#">ОРТ Предметы</a></h3>
                            <!-- /.course-category-one__title -->
                        </div><!-- /.course-category-one__single -->
                    </div><!-- /.item -->
                    <div class="item">
                        <div class="course-category-one__single color-4">
                            <div class="course-category-one__icon">
                                <i class="kipso-icon-camera"></i><!-- /.kipso-icon-camera -->
                            </div><!-- /.course-category-one__icon -->
                            <h3 class="course-category-one__title"><a href="#">ОРТ</a></h3>
                            <!-- /.course-category-one__title -->
                        </div><!-- /.course-category-one__single -->
                    </div><!-- /.item -->
                </div><!-- /.course-category-one__carousel owl-carousel owl-theme -->

                <a href="#" class="thm-btn">View All Categories</a><!-- /.thm-btn -->
            </div><!-- /.container-fluid -->
        </section><!-- /.thm-gray-bg course-category-one -->
        <section class="cta-three">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 clearfix">
                        <img :src="require('../../assets/images/cta-1.jpeg')" class="cta-three__image" alt="">
                    </div><!-- /.col-lg-6 -->
                    <div class="col-lg-6">
                        <div class="cta-three__content">
                            <div class="block-title text-left">
                                <h2 class="block-title__title">Benefits of learning
                                    with kipso</h2><!-- /.block-title__title -->
                            </div><!-- /.block-title -->
                            <p class="cta-three__text">There cursus massa at urnaaculis estie. Sed aliquamellus vitae ultrs
                                condmentum leo massa mollis estiegittis miristum nulla sed medy fringilla vitae.</p>
                            <!-- /.cta-three__text -->
                            <div class="cta-three__single-wrap">
                                <div class="cta-three__single">
                                    <i class="kipso-icon-strategy"></i><!-- /.kipso-icon-strategy -->
                                    <p class="cta-three__single-text">Professional
                                        Courses</p><!-- /.cta-three__single-text -->
                                </div><!-- /.cta-three__single -->
                                <div class="cta-three__single">
                                    <i class="kipso-icon-training"></i><!-- /.kipso-icon-training -->
                                    <p class="cta-three__single-text">Live
                                        Learning</p><!-- /.cta-three__single-text -->
                                </div><!-- /.cta-three__single -->
                                <div class="cta-three__single">
                                    <i class="kipso-icon-human-resources"></i><!-- /.kipso-icon-human-resources -->
                                    <p class="cta-three__single-text">Expert
                                        Teachers</p><!-- /.cta-three__single-text -->
                                </div><!-- /.cta-three__single -->
                            </div><!-- /.cta-three__single-wrap -->
                            <a href="#" class="thm-btn">Learn More</a><!-- /.thm-btn -->
                        </div><!-- /.cta-three__content -->
                    </div><!-- /.col-lg-6 -->
                </div><!-- /.row -->
            </div><!-- /.container -->
        </section><!-- /.cta-three -->
        <section class="brand-two ">
            <div class="container">
                <div class="block-title">
                    <h2 class="block-title__title">Our company & partners</h2><!-- /.block-title__title -->
                </div><!-- /.block-title -->
                <div class="brand-one__carousel owl-carousel owl-theme">
                    <div class="item">
                        <img :src="require('../../assets/images/brand-1-1.png')" alt="">
                    </div><!-- /.item -->
                    <div class="item">
                        <img :src="require('../../assets/images/brand-1-1.png')" alt="">
                    </div><!-- /.item -->
                    <div class="item">
                        <img :src="require('../../assets/images/brand-1-1.png')" alt="">
                    </div><!-- /.item -->
                    <div class="item">
                        <img :src="require('../../assets/images/brand-1-1.png')" alt="">
                    </div><!-- /.item -->
                    <div class="item">
                        <img :src="require('../../assets/images/brand-1-1.png')" alt="">
                    </div><!-- /.item -->
                    <div class="item">
                        <img :src="require('../../assets/images/brand-1-1.png')" alt="">
                    </div><!-- /.item -->
                    <div class="item">
                        <img :src="require('../../assets/images/brand-1-1.png')" alt="">
                    </div><!-- /.item -->
                    <div class="item">
                        <img :src="require('../../assets/images/brand-1-1.png')" alt="">
                    </div><!-- /.item -->
                    <div class="item">
                        <img :src="require('../../assets/images/brand-1-1.png')" alt="">
                    </div><!-- /.item -->
                    <div class="item">
                        <img :src="require('../../assets/images/brand-1-1.png')" alt="">
                    </div><!-- /.item -->
                </div><!-- /.brand-one__carousel owl-carousel owl-theme -->
            </div><!-- /.container -->
        </section><!-- /.brand-one -->
        <section class="blog-two">
            <div class="container">
                <div class="block-title text-center">
                    <h2 class="block-title__title">Our latest news <br>
                        & articles</h2><!-- /.block-title__title -->
                </div><!-- /.block-title -->
                <div class="blog-two__carousel owl-carousel owl-theme">
                    <div class="item">
                        <div class="blog-two__single" style="background-image: url(/images/blog-2-1.jpeg);">
                            <div class="blog-two__inner">
                                <a href="news-details.html" class="blog-two__date">
                                    <span>25</span>
                                    Jul
                                </a><!-- /.blog-two__date -->
                                <div class="blog-two__meta">
                                    <a href="#" >by Admin</a>
                                    <a href="#">3 Comments</a>
                                </div><!-- /.blog-two__meta -->
                                <h3 class="blog-two__title">
                                    <a href="news-details.html">Summer high school journalism camp</a>
                                </h3><!-- /.blog-two__title -->
                            </div><!-- /.blog-two__inner -->
                        </div><!-- /.blog-two__single -->
                    </div><!-- /.item -->
                    <div class="item">
                        <div class="blog-two__single" style="background-image: url(/images/blog-2-2.jpeg);">
                            <div class="blog-two__inner">
                                <a href="news-details.html" class="blog-two__date">
                                    <span>25</span>
                                    Jul
                                </a><!-- /.blog-two__date -->
                                <div class="blog-two__meta">
                                    <a href="#">by Admin</a>
                                    <a href="#">3 Comments</a>
                                </div><!-- /.blog-two__meta -->
                                <h3 class="blog-two__title">
                                    <a href="news-details.html">Get a tips to develop a quality education</a>
                                </h3><!-- /.blog-two__title -->
                            </div><!-- /.blog-two__inner -->
                        </div><!-- /.blog-two__single -->
                    </div><!-- /.item -->
                    <div class="item">
                        <div class="blog-two__single" style="background-image: url(/images/blog-2-3.jpeg);">
                            <div class="blog-two__inner">
                                <a href="news-details.html" class="blog-two__date">
                                    <span>25</span>
                                    Jul
                                </a><!-- /.blog-two__date -->
                                <div class="blog-two__meta">
                                    <a href="#" >by Admin</a>
                                    <a href="#">3 Comments</a>
                                </div><!-- /.blog-two__meta -->
                                <h3 class="blog-two__title">
                                    <a href="news-details.html">Learn variety of programs and courses</a>
                                </h3><!-- /.blog-two__title -->
                            </div><!-- /.blog-two__inner -->
                        </div><!-- /.blog-two__single -->
                    </div><!-- /.item -->
                    <div class="item">
                        <div class="blog-two__single" style="background-image: url(/images/blog-2-1.jpeg);">
                            <div class="blog-two__inner">
                                <a href="news-details.html" class="blog-two__date">
                                    <span>25</span>
                                    Jul
                                </a><!-- /.blog-two__date -->
                                <div class="blog-two__meta">
                                    <a href="#" >by Admin</a>
                                    <a href="#" >3 Comments</a>
                                </div><!-- /.blog-two__meta -->
                                <h3 class="blog-two__title">
                                    <a href="news-details.html" >Summer high school journalism camp</a>
                                </h3><!-- /.blog-two__title -->
                            </div><!-- /.blog-two__inner -->
                        </div><!-- /.blog-two__single -->
                    </div><!-- /.item -->
                    <div class="item">
                        <div class="blog-two__single" style="background-image: url(/images/blog-2-2.jpeg);">
                            <div class="blog-two__inner">
                                <a href="news-details.html" class="blog-two__date">
                                    <span>25</span>
                                    Jul
                                </a><!-- /.blog-two__date -->
                                <div class="blog-two__meta">
                                    <a href="#" >by Admin</a>
                                    <a href="#" >3 Comments</a>
                                </div><!-- /.blog-two__meta -->
                                <h3 class="blog-two__title">
                                    <a href="news-details.html">Get a tips to develop a quality education</a>
                                </h3><!-- /.blog-two__title -->
                            </div><!-- /.blog-two__inner -->
                        </div><!-- /.blog-two__single -->
                    </div><!-- /.item -->
                    <div class="item">
                        <div class="blog-two__single" style="background-image: url(/images/blog-2-3.jpeg);">
                            <div class="blog-two__inner">
                                <a href="news-details.html" class="blog-two__date">
                                    <span>25</span>
                                    Jul
                                </a><!-- /.blog-two__date -->
                                <div class="blog-two__meta">
                                    <a href="#" >by Admin</a>
                                    <a href="#" >3 Comments</a>
                                </div><!-- /.blog-two__meta -->
                                <h3 class="blog-two__title">
                                    <a href="news-details.html">Learn variety of programs and courses</a>
                                </h3><!-- /.blog-two__title -->
                            </div><!-- /.blog-two__inner -->
                        </div><!-- /.blog-two__single -->
                    </div><!-- /.item -->
                    <div class="item">
                        <div class="blog-two__single" style="background-image: url(/images/blog-2-1.jpeg);">
                            <div class="blog-two__inner">
                                <a href="news-details.html" class="blog-two__date">
                                    <span>25</span>
                                    Jul
                                </a><!-- /.blog-two__date -->
                                <div class="blog-two__meta">
                                    <a href="#" >by Admin</a>
                                    <a href="#">3 Comments</a>
                                </div><!-- /.blog-two__meta -->
                                <h3 class="blog-two__title">
                                    <a href="news-details.html">Summer high school journalism camp</a>
                                </h3><!-- /.blog-two__title -->
                            </div><!-- /.blog-two__inner -->
                        </div><!-- /.blog-two__single -->
                    </div><!-- /.item -->
                    <div class="item">
                        <div class="blog-two__single" style="background-image: url(/images/blog-2-2.jpeg);">
                            <div class="blog-two__inner">
                                <a href="news-details.html" class="blog-two__date">
                                    <span>25</span>
                                    Jul
                                </a><!-- /.blog-two__date -->
                                <div class="blog-two__meta">
                                    <a href="#">by Admin</a>
                                    <a href="#">3 Comments</a>
                                </div><!-- /.blog-two__meta -->
                                <h3 class="blog-two__title">
                                    <a href="news-details.html">Get a tips to develop a quality education</a>
                                </h3><!-- /.blog-two__title -->
                            </div><!-- /.blog-two__inner -->
                        </div><!-- /.blog-two__single -->
                    </div><!-- /.item -->
                    <div class="item">
                        <div class="blog-two__single" style="background-image: url(images/blog-2-3.jpeg);">
                            <div class="blog-two__inner">
                                <a href="news-details.html" class="blog-two__date">
                                    <span>25</span>
                                    Jul
                                </a><!-- /.blog-two__date -->
                                <div class="blog-two__meta">
                                    <a href="#">by Admin</a>
                                    <a href="#">3 Comments</a>
                                </div><!-- /.blog-two__meta -->
                                <h3 class="blog-two__title">
                                    <a href="news-details.html">Learn variety of programs and courses</a>
                                </h3><!-- /.blog-two__title -->
                            </div><!-- /.blog-two__inner -->
                        </div><!-- /.blog-two__single -->
                    </div><!-- /.item -->
                </div><!-- /.blog-two__carousel owl-carousel owl-theme -->
            </div><!-- /.container -->
        </section><!-- /.blog-one blog-page -->
        <section class="cta-four">
            <img :src="require('../../assets/images/circle-stripe-1.png')" class="cta-four__stripe" alt="">
            <img :src="require('../../assets/images/line-stripe-1.png')" class="cta-four__line" alt="">
            <div class="container text-center">
                <div class="block-title">
                    <h2 class="block-title__title">We’ve best teachers <br>
                        in every subject</h2><!-- /.block-title__title -->
                </div><!-- /.block-title -->
                <p class="cta-four__text">Lorem ipsum gravida nibh vel velit auctor aliquetnean sollicitudin, lorem quis
                    bibendum auci elit <br> consequat is simply free text available in the psutis sem nibh id eis sed odio sit
                    amet.</p><!-- /.cta-four__text -->
            </div><!-- /.container text-center -->
        </section><!-- /.cta-four -->
        <section class="mailchimp-one">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="mailchimp-one__content">
                            <div class="mailchimp-one__icon">
                                <i class="kipso-icon-email"></i><!-- /.kipso-icon-email -->
                            </div><!-- /.mailchimp-one__icon -->
                            <h2 class="mailchimp-one__title">Get latest courses <br>
                                updates by signing up</h2><!-- /.mailchimp-one__title -->
                        </div><!-- /.mailchimp-one__content -->
                    </div><!-- /.col-lg-6 -->
                    <div class="col-lg-6 d-flex">
                        <div class="my-auto">
                            <form action="#" class="mailchimp-one__form mc-form" data-url="MAILCHIMP_FORM_URL">
                                <input type="text" id="mc-email" placeholder="Enter your email ">
                                <button type="submit" class="thm-btn">Subscribe</button>
                            </form><!-- /.mailchimp-one__form -->
                            <div class="mc-form__response"></div><!-- /.mc-form__response -->
                        </div><!-- /.my-auto -->
                    </div><!-- /.col-lg-6 -->
                </div><!-- /.row -->
            </div><!-- /.container -->
        </section><!-- /.mailchimp-one -->
        <Footer></Footer><!-- /.site-footer -->
    </div><!-- /.page-wrapper -->
  </div>
</template>



<script>
import Footer from '../../components/footer/Footer.vue'
import PreLoader from '../../components/preloader/PreLoader.vue'
import '../../assets/css/style.css'
  export default {
    name: 'Main',
    components:{
      Footer,
      PreLoader
    },
    data: () => ({
      isLoading: false,
    }),
    mounted() {
        this.isLoading = true;
        setTimeout(()=>{
            this.isLoading = false;
        },1000)
    },
    methods :{
      goTo (url) {
         this.link = url;
         switch (url) {
            case 'Courses':
              this.$router.push({ name: 'Courses' });
               break;
            case 'Teachers':
               this.$router.push({ name: 'Teachers' });
               break;
            case 'News':
               this.$router.push({ name: 'News' });
               break;
            case 'About':
               this.$router.push({ name: 'About' });
               break;
            case 'Home':
               this.$router.push({ name: 'Main' });
               break;
         }
      },
    }
  }
</script>
<style lang="scss" scoped>
.main{
  max-width: 100%;
  max-height: 100%;
  /*font-family: "Popins";
  font-weight: 700;*/
  
}


</style>